<template>
  <div class="header--normal">

    <!-- ===== header/ ===== -->
    <header>
      <div
        class="header_container"
        :style="
          isMessageChat
            ? { maxWidth: '100%', paddingRight: '20px', paddingLeft: '20px' }
            : {}
        "
      >
        <!-- === header_detail/ === -->
        <div class="header_detail">
          <div class="header_siteid">
            <a class="siteid_logo"
              >スキルが活きる、スキルで生きる スキイキ produced by マイナビ</a
            >
          </div>
          <!-- PC版リンク -->
          <div class="header_link_detail" v-if="$mq == 'pc'">
              <a class="btn--jobsearch btn--header_link_item">
                <span>案件を探す</span>
              </a>
              <a class="btn--favorite btn--header_link_item">
                <span>気になるリスト</span>
              </a>
          </div>
          <!-- SP版リンク -->
          <div class="header_link_detail" v-else>
              <a class="btn--jobsearch btn--header_link_item">
                <img class="img--message_icon" src="/assets/images/common/icn_search_bl_line.svg" alt />
                <span>案件検索</span>
              </a>
              <a class="btn--favorite btn--header_link_item">
                <img class="img--message_icon" src="/assets/images/common/icn_nav_star_new.svg" alt />
                <span>気になる</span>
              </a>
          </div>

          <nav v-if="$mq == 'pc'" class="header_nav_member">
            <ul class="header_nav_member_detail header_nav_icon_list">
              <li class="header_nav_user delsp">
                  <span class="userimage">
                    <img :src="profileImage" alt />
                  </span>
              </li>
              <li class="list_item--nav_icon">
                <a class="pcMenuIcon">
                    <img class="inactive" src="/assets/images/parts/icon/header/sp_header_menu.png">
                </a>
              </li>
            </ul>
          </nav>
          <a class="spMenuIcon" v-else>
              <img class="inactive" src="/assets/images/parts/icon/header/sp_header_menu.png">
          </a>
        </div>
        <!-- === /header_detail === -->
      </div>
      <!-- /header_container -->
    </header>
    <!-- ===== /header ===== -->
  </div>
</template>

<script>
const listener = {
  methods: {
    listen: function (target, eventType, callback) {
      if (!this._eventRemovers) {
        this._eventRemovers = [];
      }
      target.addEventListener(eventType, callback);
      this._eventRemovers.push({
        remove: function () {
          target.removeEventListener(eventType, callback);
        },
      });
    },
  },
  destroyed: function () {
    if (this._eventRemovers) {
      this._eventRemovers.forEach(function (eventRemover) {
        eventRemover.remove();
      });
    }
  },
};

export default {
  name: "Header",
  props: {},
  mixins: [listener],
  data() {
    return {
      showMenu: false,
    };
  },
  computed: {
    profileImage() {
      return "/assets/images/dummy/prof_noimage3.png";
    },
  },
  methods: {
  },
  mounted() {
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
