import BaseAPIClient from './client'


class APIClient {
	constructor(endpoint) {
		this.client = new BaseAPIClient(endpoint)
	}

	getEntryPoint() {
		return this.client.getEntryPoint()
	}

	getConstants() {
		return new Promise((resolve, reject) => {
			this.client.get('/constants').then(response => {
				if (response.status == 200) {
					resolve(response.data)
				}
				else {
					reject({
						code: "ErrorStatusEception",
						error: response
					})
				}
			}).catch(e => {
				reject({
					code: "APIErrorException",
					error: e
				})
			});
		})
	}

	getConstantsSkillsCategoryCodeSubCategoryCode(categoryCode, subcategoryCode) {
		return new Promise((resolve, reject) => {
			this.client.get('/constants/skills/' + categoryCode + '/' + subcategoryCode).then(response => {
				if (response.status == 200) {
					resolve(response.data)
				}
				else {
					reject(response)
				}
			}).catch(e => {
				reject(e)
			});
		})
	}

	getConstantsSkills(queries) {
		return new Promise((resolve, reject) => {
			const qs = []
			if (queries.q) {
				qs.push('q=' + encodeURIComponent(queries.q))
			}
			if (queries.code) {
				qs.push('code=' + queries.code.join(encodeURIComponent(",")))
			}
			if (qs.length == 0) {
				resolve([])
			}
			this.client.get('/constants/skills?' + qs.join("&")).then(response => {
				if (response.status == 200) {
					resolve(response.data)
				}
				else {
					reject(response)
				}
			}).catch(e => {
				reject(e)
			});
		})
	}

	getConstantsBanks(query) {
		return new Promise((resolve, reject) => {
			let qs = ''
			if (query) {
				console.log(query)
				if (query.query) {
					qs += 'query=' + encodeURIComponent(query.query)
				}
			}
			this.client.get('/constants/banks?' + qs).then(response => {
				if (response.status == 200) {
					resolve(response.data)
				}
				else {
					reject(response)
				}
			}).catch(e => {
				reject(e)
			});
		})
	}

	getConstantsBanksBankCode(bankCode, query) {
		return new Promise((resolve, reject) => {
			let qs = ''
			if (query) {
				if (query.query) {
					qs += 'query=' + encodeURIComponent(query.query)
				}
			}
			this.client.get('/constants/banks/' + bankCode + '?' + qs).then(response => {
				if (response.status == 200) {
					resolve(response.data)
				}
				else {
					reject(response)
				}
			}).catch(e => {
				reject(e)
			});
		})
	}

	search(conditions) {
		return new Promise((resolve, reject) => {
			console.log(conditions)
			this.client.post('/jobs', conditions).then(response => {
				if (response.status == 200) {
					resolve(response.data)
				}
				else {
					reject(response)
				}
			}).catch(e => {
				reject(e)
			});
		})
	}

	getJob(jobId) {
		return new Promise((resolve, reject) => {
			this.client.get('/jobs/' + jobId).then(response => {
				if (response.status == 200) {
					resolve(response.data)
				}
				else {
					reject(response)
				}
			}).catch(e => {
				reject(e)
			});
		})
	}

	postProfile(params) {
		return new Promise((resolve) => {
			this.client.post("/profile", params).then((response) => {
				resolve(response.data)
			}).catch((error) => {
				console.error(error);
				alert('エラーが発生しました')
				resolve({
					code: "APIErrorException",
					error: error
				})
			});
		});
	}
	// 非会員エントリー 仮登録
	postTmpProfileWithEntry(params) {
		return new Promise((resolve) => {
			this.client.post("/profile/tmp/entry", params).then((response) => {
				resolve(response.data)
			}).catch((error) => {
				console.error(error);
				alert('エラーが発生しました')
				resolve({
					code: "APIErrorException",
					error: error
				})
			});
		});
	}
	// 通常会員 仮登録
	postTmpProfile(params) {
		return new Promise((resolve) => {
			this.client.post("/profile/tmp", params).then((response) => {
				resolve(response.data)
			}).catch((error) => {
				console.error(error);
				alert('エラーが発生しました')
				resolve({
					code: "APIErrorException",
					error: error
				})
			});
		});
	}
	// アカウント検証（仮会員→本登録 & 非会員エントリー → 本登録）用
	postMemberConfirmation(username,confirmation_code) {
		return new Promise((resolve, reject) => {
		  ( async() => { // eslint-disable-line
				const params = {
					username:username,
					confirmation_code:confirmation_code
				}
				this.client.post("/profile/confirmation", params).then((response) => {
					resolve(response.data)
				}).catch((error) => {
					console.error(error);
					reject({
						code: "APIErrorException",
						error: error
					})
				});
			})();
		});
	}

	getSNSAuthorizeToken(token) {
		return new Promise((resolve, reject) => {
			this.client.get('/sns/authorize/' + token).then(response => {
				if (response.status == 200) {
					resolve(response.data)
				}
				else {
					reject({
						code: "ErrorStatusEception",
						error: response
					})
				}
			}).catch(e => {
				reject({
					code: "APIErrorException",
					error: e
				})
			});
		})
	}

	getSNSLoginToken(token) {
		return new Promise((resolve, reject) => {
			this.client.get('/sns/login/' + token).then(response => {
				if (response.status == 200) {
					resolve(response.data)
				}
				else {
					reject({
						code: "ErrorStatusEception",
						error: response
					})
				}
			}).catch(e => {
				reject({
					code: "APIErrorException",
					error: e
				})
			});
		})
	}

	getProxyLoginToken(token) {
		return new Promise((resolve, reject) => {
			this.client.post('/loginCheck/proxy' ,{"token":token}).then(response => {
				if (response.status == 200) {
					resolve(response.data)
				}
				else {
					reject({
						code: "ErrorStatusEception",
						error: response
					})
				}
			}).catch(e => {
				reject({
					code: "APIErrorException",
					error: e
				})
			});
		})
	}

	postCheckWithdrawal(memberId) {
		return new Promise((resolve, reject) => {
			this.client.post('/loginCheck/withdrawal' ,{"memberId":memberId}).then(response => {
				if (response.status == 200) {
					resolve(response.data)
				}
				else {
					reject({
						code: "ErrorStatusEception",
						error: response
					})
				}
			}).catch(e => {
				reject({
					code: "APIErrorException",
					error: e
				})
			});
		})
	}

	postPasswordLock(reCaptchaToken) {
		return new Promise((resolve) => {
			this.client.post("/loginCheck", { 'reCaptchaToken': reCaptchaToken }).then((response) => {
				console.log(response)
				resolve(response.data)
			}).catch((error) => {
				console.error(error);
				resolve({
					code: "APIErrorException",
					error: error
				})
			});
		});
	}

	getJobCompanyIdPreviewKey(companyId, key) {
		return new Promise((resolve, reject) => {

			this.client.get('/job/' + companyId + '/preview/' + key).then(response => {
				if (response.status == 200) {
					resolve(response.data)
				}
				else {
					reject({
						code: "ErrorStatusEception",
						error: response
					})
				}
			}).catch(e => {
				reject({
					code: "APIErrorException",
					error: e
				})
			});
		})
	}

	getJobCompanyIdJobIdPreview(companyId, JobId) {
		return new Promise((resolve, reject) => {

			this.client.get('/job/' + companyId + '/' + JobId + '/preview').then(response => {
				if (response.status == 200) {
					resolve(response.data)
				}
				else {
					reject({
						code: "ErrorStatusEception",
						error: response
					})
				}
			}).catch(e => {
				reject({
					code: "APIErrorException",
					error: e
				})
			});
		})
	}


	postReminder(params) {
		return new Promise((resolve, reject) => {
			this.client.post("/reminder", params).then((response) => {
				resolve(response.data)
			}).catch((error) => {
				// console.error(error)
				reject({
					code: "APIErrorException",
					error: error
				})
			});
		});
	}

	postReminderKey(key, params) {
		return new Promise((resolve, reject) => {
			this.client.post("/reminder/" + key, params).then((response) => {
				resolve(response.data)
			}).catch((error) => {
				console.error(error);
				reject({
					code: "APIErrorException",
					error: error
				})
			});
		});
	}
	postReminderKeyEmail(key) {
		return new Promise((resolve, reject) => {
			this.client.post("/reminder/" + key + "/email",).then((response) => {
				resolve(response.data)
			}).catch((error) => {
				console.error(error);
				reject({
					code: "APIErrorException",
					error: error
				})
			});
		});
	}

	postContact(params) {
		return new Promise((resolve, reject) => {
			this.client.post("/contact", params).then((response) => {
				resolve(response.data)
			}).catch((error) => {
				console.error(error);
				reject({
					code: "APIErrorException",
					error: error
				})
			});
		});
	}

	getCompanyInfo(companyId) {
		return new Promise((resolve, reject) => {
			this.client.get('/company/' + String(companyId)).then((response) => {
				if (response.status == 200) {
					resolve(response.data)
				}
				else {
					reject(response)
				}
			}).catch(e => {
				reject(e)
			});
		})
	}

	getAbuseCompanyInfo(abuseCompanyId) {
		return new Promise((resolve, reject) => {
			this.client.get('/company/abuse/' + abuseCompanyId).then((response) => {
				if (response.status == 200) {
					resolve(response.data)
				}
				else {
					reject(response)
				}
			}).catch(e => {
				reject(e)
			});
		})
	}

	getRecommendedJobs(jobId) {
		return new Promise((resolve, reject) => {
			this.client.get(`/jobs/${jobId}/recommend`).then((response) => {
				if (response.status == 200) {
					resolve(response.data)
				}
				else {
					reject(response)
				}
			}).catch(e => {
				reject(e)
			});
		})
	}

	postCompanyStatuses(params) {
		return new Promise((resolve, reject) => {
			this.client.post("/company/statuses", params).then((response) => {
				resolve(response.data)
			}).catch((error) => {
				console.error(error);
				reject({
					code: "APIErrorException",
					error: error
				})
			});
		});
	}

	getFeatureJobs() {
		return new Promise((resolve, reject) => {
			this.client.get('/jobs/feature').then((response) => {
				if (response.status == 200) {
					resolve(response.data)
				}
				else {
					reject(response)
				}
			}).catch(e => {
				reject(e)
			});
		})
	}

	getJobCompanyIdJobIdRevisionRevisionIdPreview(companyId, JobId, revisionId) {
		return new Promise((resolve, reject) => {

			this.client.get('/job/' + companyId + '/' + JobId + '/revision/' + revisionId + '/preview').then(response => {
				if (response.status == 200) {
					resolve(response.data)
				}
				else {
					reject({
						code: "ErrorStatusException",
						error: response
					})
				}
			}).catch(e => {
				reject({
					code: "APIErrorException",
					error: e
				})
			});
		})
	}

	getTopics() {
		return new Promise((resolve, reject) => {
			this.client.get(`/topics`).then((response) => {
				if (response.status == 200) {
					resolve(response.data)
				}
				else {
					reject(response)
				}
			}).catch(e => {
				reject(e)
			});
		})
	}

	getTopic(topicId) {
		return new Promise((resolve, reject) => {
			this.client.get(`/topics/${topicId}`).then((response) => {
				if (response.status == 200) {
					resolve(response.data)
				}
				else {
					reject(response)
				}
			}).catch(e => {
				reject(e)
			});
		})
	}

	postJobsNew(countOnly) {
		return new Promise((resolve, reject) => {
			this.client.post("/jobs/new", countOnly).then((response) => {
				resolve(response.data)
			}).catch((error) => {
				console.error(error);
				reject({
					code: "APIErrorException",
					error: error
				})
			});
		});
	}

	getGuides(freeWord) {
		return new Promise((resolve, reject) => {
			var apiUrl = '/guides'

			if(freeWord){
				apiUrl = `${apiUrl}?free_word=${freeWord}`
			}

			this.client.get(apiUrl).then((response) => {
				if (response.status == 200) {
					resolve(response.data)
				}
				else {
					reject(response)
				}
			}).catch(e => {
				reject(e)
			});
		})
	}

	getGuide(guideId) {
		return new Promise((resolve, reject) => {
			this.client.get(`/guides/${guideId}`).then((response) => {
				if (response.status == 200) {
					resolve(response.data)
				}
				else {
					reject(response)
				}
			}).catch(e => {
				reject(e)
			});
		})
	}

	getGuidePreviewKey(key) {
		return new Promise((resolve, reject) => {

			this.client.get('/guides/preview/' + key).then(response => {
				if (response.status == 200) {
					resolve(response.data)
				}
				else {
					reject({
						code: "ErrorStatusEception",
						error: response
					})
				}
			}).catch(e => {
				reject({
					code: "APIErrorException",
					error: e
				})
			});
		})
	}

	getMagazines() {
		return new Promise((resolve, reject) => {
			this.client.get(`/magazine`).then((response) => {
				if (response.status == 200) {
					resolve(response.data)
				} else {
					reject(response)
				}
			}).catch(e => {
				reject(e)
			})
		})
	}
	getMagazinesByPaging(page) {
		return new Promise((resolve, reject) => {
			this.client.get(`/magazine?page=${page}`).then((response) => {
				if (response.status == 200) {
					console.log("OK");
					resolve(response.data)
				} else {
					console.log("reject");

					reject(response)
				}
			}).catch(e => {
				reject(e)
			})
		})
	}
	getMagazinesByCategory(key) {
		return new Promise((resolve, reject) => {
			this.client.get(`/magazine?categoryCode=${key}`).then((response) => {

				if (response.status == 200) {

					resolve(response.data)

					console.log(response)
				} else {
					reject(response)
				}
			}).catch(e => {
				reject(e)
			})
		})
	}

	getMagazine(magazineId) {
		return new Promise((resolve, reject) => {
			this.client.get(`/magazine/${magazineId}`).then((response) => {
				if (response.status == 200) {
					resolve(response.data)
				} else {
					reject(response)
				}
			}).catch(e => {
				reject(e)
			})
		})
	}

	getMagazinePreviewKey(key) {
		return new Promise((resolve, reject) => {

			this.client.get('/magazine/preview/' + key).then(response => {
				if (response.status == 200) {
					resolve(response.data)
				}
				else {
					reject({
						code: "ErrorStatusEception",
						error: response
					})
				}
			}).catch(e => {
				reject({
					code: "APIErrorException",
					error: e
				})
			});
		})
	}

	getRelationalJobsForMagazine(params) {
		return new Promise((resolve, reject) => {

			var jobCategories = params.jobCategories;

			var url = "";

			if ( params.jobCategories == null || params.jobCategories == "" ) {

				url = '/magazine/' + params.magazineId + '/jobs'

			} else {

				url = '/magazine/' + params.magazineId + '/jobs' + '?jobCategories=' + jobCategories
			}

			console.log(url)

			this.client.get(url).then(response => {
				if (response.status == 200) {
					console.log(response.data)
					resolve(response.data)
				}
				else {
					reject({
						code: "ErrorStatusEception",
						error: response
					})
				}
			}).catch(e => {
				reject({
					code: "APIErrorException",
					error: e
				})
			});
		})
	}

	postPublish(params) {
		return new Promise((resolve) => {
			this.client.post("/publish", params).then((response) => {
				resolve(response.data)
			}).catch((error) => {
				console.error(error);
				alert('エラーが発生しました')
				resolve({
					code: "APIErrorException",
					error: error
				})
			});
		});
	}

	getBanners() {
		return new Promise((resolve, reject) => {
			this.client.get(`/banners`).then((response) => {
				if (response.status == 200) {
					resolve(response.data)
				} else {
					reject(response)
				}
			}).catch(e => {
				reject(e)
			})
		})
	}

	getMagazineBanners() {
		return new Promise((resolve, reject) => {
			this.client.get(`/magazine/banner`).then((response) => {
				if (response.status == 200) {
					resolve(response.data)
				} else {
					reject(response)
				}
			}).catch(e => {
				reject(e)
			})
		})
	}

	getMypageBanners() {
		return new Promise((resolve, reject) => {
			this.client.get(`/mypage/banner`).then((response) => {
				if (response.status == 200) {
					resolve(response.data)
				} else {
					reject(response)
				}
			}).catch(e => {
				reject(e)
			})
		})
	}

	getPickups() {
		return new Promise((resolve, reject) => {
			this.client.get(`/pickups`).then((response) => {
				console.log(response)
				if (response.status == 200) {
					resolve(response.data)
				}
				else {
					reject(response)
				}
			}).catch(e => {
				reject(e)
			});
		})
	}

	getPickup(pickupId) {
		return new Promise((resolve, reject) => {
			this.client.get(`/pickups/${pickupId}`).then((response) => {
				if (response.status == 200) {
					resolve(response.data)
				}
				else {
					reject(response)
				}
			}).catch(e => {
				reject(e)
			});
		})
	}

	checkPostedJob(jobId, companyId) {
		return new Promise((resolve, reject) => {
			this.client.get(`/jobs/${jobId}/${companyId}`).then((response) => {
				if (response.status == 200) {
					resolve(response)
				}
				else {
					reject(response)
				}
			}).catch(e => {
				reject(e)
			});
		})
	}

	postAccessLog(fullPath,path,memberId,userAgent,referer,uuuid) {

		// 非同期
		this.client.post("/accesslog", {
			"fullPath": fullPath,
			"path": path,
			"memberId": memberId,
			"userAgent" : userAgent,
			"referer" : referer,
			"uuuid" : uuuid
		}).catch(e => {
			console.log(e)
		});
	}

	// clickログ用
	postBannerLog(bannerId) {

		this.client.post("/log", {
			"logType": "banner_click",
			"bannerId": bannerId,
		}).catch(e => {
			console.log(e)
		});
	}
	postMagazineBannerLog(bannerId) {

		this.client.post("/log", {
			"logType": "magazine_banner_click",
			"bannerId": bannerId,
		}).catch(e => {
			console.log(e)
		});
	}
	postMypageBannerLog(bannerId) {

		this.client.post("/log", {
			"logType": "mypage_banner_click",
			"bannerId": bannerId,
		}).catch(e => {
			console.log(e)
		});
	}
	// clickログ用
	postFreeWordLog(keyword) {

		this.client.post("/log", {
			"logType": "free_word",
			"content": keyword,
		}).catch(e => {
			console.log(e)
		});
	}

	//reCAPTCHA判定用
	async checkShowRecaptcha() { 

		return await new Promise((resolve, reject) => {
			(async () => {
				this.client.post("/showRecaptcha").then((response) => {
					console.log(response)
					resolve(response.data.showRecaptcha)				
				}).catch((error) => {
					console.error(error);
					reject({
						code: "APIErrorException",
						error: error
					})
				});
			})();
		})
	}
	
}

export default new APIClient(process.env.VUE_APP_PUBLIC_API_ENDPOINT)
