import Vue from 'vue'

import PublicAPI from '@/api/public'
import MemberAPI from '@/api/member'

export default {
    namespaced: true,
    state: {
        models: {},
        entryCompleted: false,
        newJobInfo: {},
        dummyJob:{
            aboutUsText: '',
            companyId: '',
            companyImage: '',
            companyName:'',
            contractType:[{code:'',name:''}],
            features: [],
            frequencyOfGoingToOffice: [],
            helpText:'',
            id: '',
            image: '',
            isApproachingExpire: false,
            isNew: false,
            isHiring: false,
            jobCategory:[],
            jobType:[],
            lastUpdateDate:[],
            locationAddress:'',
            locationCity:'',
            locationPrefecture:{},
            optionText: '',
            optionTitle:'',
            period: [],
            periodExtendable: false,
            publishEndDate: '',
            recommendedQualifications:[],
            recommendedSkillText: '',
            recommendedSkills:[],
            requiredQualifications:[],
            requiredSkillText:'',
            requiredSkills:[],
            skillMatchRate:null,
            taxWithholding:false,
            title:'',
            total_job_entries_count:0,
            total_job_interests_count:0,
            total_job_views_count:0,
            unitPrice:'',
            workStyles:[],
            workingTime:'',
            contractUnitPrice:'',
            rewardContractNegotiable:false,
            contractContinuation:false,
            aboutDeliverable:'',
            aboutDeliverableSupplement:'',
            deliverablePeriod:[],
            deliverablePeriodNegotiable:false,
            aboutOperation:'',
            reconsignment:[],
        }
    },
    mutations: {
        setModel(state, model){
            Vue.set(state.models, model.id, model)
        },
        setEntryCompleted(state, v){
            state.entryCompleted = v
        },
        setNewJobInfo(state, v){
            state.newJobInfo = v
        },

        clearModels(state){
            state.models = {}
        }
    },
    actions: {
        loadModel({commit}, jobId){
            return new Promise((resolve)=>{
                (async()=>{
                    const model = await PublicAPI.getJob(jobId).catch(e=>{
                        console.log(e)
                        return false;
                    })
                    commit('setModel', model)
                    resolve(model)
                })();
            })
        },
        entry({commit}, jobId){
            return new Promise((resolve, reject)=>{
                (async()=>{
                    try{
                        await MemberAPI.postJobIdEntry(jobId).then((response)=>{
                            commit('setEntryCompleted', true)
                            resolve(response)
                        })
                        resolve()
                    }
                    catch(e){
                        reject(e)
                    }
                })();
            })
        },
        getNewJobInfo({commit}, countOnly){
            let param = false
            if(!countOnly){
                param = false
            }else{
                param = true
            }
            //  名前はGETだが、パラメータを送りたい為POSTメソッドを使用
            return new Promise((resolve, reject)=>{
                (async()=>{
                    try{
                        await PublicAPI.postJobsNew({countOnly: param}).then((response)=>{
                            commit('setNewJobInfo', response)

                            resolve(response)
                        })

                        resolve()
                    }
                    catch(e){
                        reject(e)
                    }
                })();
            })
        }
    },
}
